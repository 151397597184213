import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { PDF } from "../../../assets/icons/PDF.js";

const useStyles = makeStyles({
  body: {
    fontFamily: "Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  uploadContainer: {
    background: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "100%",
    position: "relative",
    transition: "transform 0.2s ease-in-out",
  },
  uploadContainerShrunk: {
    transform: "scale(0.5)",
  },
  heading: {
    margin: "0 0 15px",
    fontSize: "1.5em",
    color: "#333",
  },
  uploadLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #ccc",
    borderRadius: "8px",
    padding: "20px",
    cursor: "pointer",
    transition: "border-color 0.3s ease",
    "&:hover": {
      borderColor: "#007bff",
    },
  },
  hiddenInput: {
    display: "none",
  },
  uploadText: {
    fontSize: "1em",
    color: "#555",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadInfo: {
    marginTop: "10px",
    fontSize: "0.9em",
    color: "#777",
  },
  successMessage: {
    marginTop: "20px",
    fontSize: "1em",
    color: "#28a745",
    animation: "$fadeIn 0.3s ease-in",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fileName: {
    marginTop: "10px",
    color: "#555",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  checkmark: {
    fontSize: "2em",
    color: "#28a745",
    animation: "$fadeIn 0.3s ease-in",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkmarkText: {
    fontSize: "1em",
    color: "#28a745",
    marginTop: "10px",
  },
});

const PDFUpload = ({
  handleFileChange,
  setFileName,
  fileName,
  handleFileSizeAlert,
}) => {
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);

  useEffect(() => {
    const preventDefaultBehavior = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDrop = event => {
      preventDefaultBehavior(event);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const file = event.dataTransfer.files[0];
        if (file.size > 30 * 1024 * 1024) {
          setFileName("");
          handleFileSizeAlert();
        } else {
          handleFileChange({ target: { files: event.dataTransfer.files } });
          setFileName(file.name);
          // animateSuccess();
        }
      }
    };

    window.addEventListener("dragover", preventDefaultBehavior);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragover", preventDefaultBehavior);
      window.removeEventListener("drop", handleDrop);
    };
  }, [handleFileChange, setFileName]);

  const onFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 30 * 1024 * 1024) {
        setFileName("");
        handleFileSizeAlert();
      } else {
        handleFileChange(event);
        setFileName(file.name);
        // animateSuccess();
      }
    }
  };

  return (
    <div className={classes.body}>
      <div
        className={`${classes.uploadContainer} ${
          isShrunk ? classes.uploadContainerShrunk : ""
        }`}>
        {showSuccess ? (
          <div className={classes.checkmark}>
            <span className={classes.checkmarkText}>File captured</span>
          </div>
        ) : (
          <>
            <h2 className={classes.heading}>Upload Your PDF</h2>
            <label className={classes.uploadLabel} htmlFor="file-upload">
              <input
                id="file-upload"
                type="file"
                accept="application/pdf"
                onChange={onFileChange}
                className={classes.hiddenInput}
              />
              <span className={classes.uploadText}>
                Drag & drop a PDF file here or click to select
                {fileName && (
                  <>
                    <div className={classes.fileName}>
                      <PDF strokeColor="grey" />
                      {fileName}
                    </div>
                  </>
                )}
              </span>
            </label>
            <div className={classes.uploadInfo}>Only PDF files are allowed</div>
          </>
        )}
      </div>
    </div>
  );
};

export default PDFUpload;
