import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  ({ palette, spacing, shape, typography, breakpoint }) =>
    createStyles({
      changeExplanationMark: {
        color: "red",
        marginRight: "5px",
      },
      scopeGuidanceStepperNumber: {
        marginRight: "5px",
        marginLeft: "5px",
        paddingLeft: "5px",
        fontFamily: "GT Flexa",
        color: "#C2C9D7",
      },
      scopeGuidanceStepperNumberActive: {
        marginRight: "5px",
        marginLeft: "5px",
        paddingLeft: "5px",
        color: "#0B295F",
      },
      scopeGuidanceIconBox: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginRight: "5px",
      },
      modalTitle: {
        fontSize: "24px",
        fontFamily: "GT Flexa",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
      },
      pdfPaper: {
        position: "absolute",
        width: 500,
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
        padding: 20,
      },
      paper: {
        position: "absolute",
        width: 400,
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
        padding: spacing(2, 4, 3),
      },
      stepperIcon: {
        height: "15px",
      },
      stepperBoxActive: {
        border: `1px solid #C2C9D7`,
        borderRadius: "10px",
        padding: "3px",
        background: "#C2C9D7",
        fontFamily: "GT Flexa",
      },
      stepperBox: {
        border: `1px solid #C2C9D7`,
        borderRadius: "10px",
        padding: "3px",
        background: "#FFFFFF",
        fontFamily: "GT Flexa",
      },
      horizonalDivider: {
        margin: "20px 0",
        // padding: "30px 0px",
        position: "relative",
        height: "1px",
        background: "#C2C9D7",
        // width: "85%",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      scopeGuidanceDisabledBtn: {
        background: "#DBDBDA",
        borderRadius: "30px",
        color: "#FFFFFF",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:disabled": {
          color: "#FFFFFF",
        },
      },
      scopeGuidanceSecondaryBtn: {
        background: "#FFFFFF",
        borderRadius: "30px",
        color: "#FD750D",
        border: "1px solid #FD750D",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#FD750D",
          color: "#FFFFFF",
        },
        "&:disabled": {
          color: "#FFFFFF",
          background: "#DBDBDA",
          borderRadius: "30px",
          textTransform: "none",
          fontFamily: "GT Flexa",
          border: "0px",
        },
      },
      // TODO: TestBtn needs to swapped with secondary btn. Temporary kept not to break the existing functionality.
      scopeGuidanceTestBtn: {
        background: "#FFFFFF",
        borderRadius: "30px",
        color: "#FD750D",
        border: "1px solid #FD750D",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#FD750D",
          color: "#FFFFFF",
        },
      },
      scopeGuidancePrimaryBtn: {
        background: "#FD750D",
        borderRadius: "30px",
        color: "#FFFFFF",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#0B295F",
        },
        "&:disabled": {
          color: "#FFFFFF",
          background: "#DBDBDA",
          borderRadius: "30px",
          textTransform: "none",
          fontFamily: "GT Flexa",
        },
      },
      // TODO: NextBtn needs to swapped with primary btn. Temporary kept not to break the existing functionality.
      scopeGuidanceNextBtn: {
        background: "#FD750D",
        borderRadius: "30px",
        color: "#FFFFFF",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#0B295F",
        },
      },
      scopeGuidanceBackBtn: {
        background: "#FFFFFF",
        color: "#666666",
        borderRadius: "30px",
        border: "1px solid #666666",
        textTransform: "none",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#FD750D",
          color: "#FFFFFF",
          border: "1px solid",
        },
      },
      scopeGuidanceDeepDiveBtn: {
        background: "#FD750D",
        borderRadius: "30px",
        color: "#FFFFFF",
        textTransform: "none",
        animationName: "$shake",
        animationDuration: "1s",
        animationIterationCount: "1",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#0B295F",
        },
      },
      "@keyframes blink": {
        "0%": { opacity: 1 },
        "50%": { opacity: 0 },
        "100%": { opacity: 1 },
      },
      "@keyframes shake": {
        "0%": { transform: "translateX(0)" },
        "25%": { transform: "translateX(-5px)" },
        "50%": { transform: "translateX(5px)" },
        "75%": { transform: "translateX(-5px)" },
        "100%": { transform: "translateX(0)" },
      },
      scopeGuidanceStepperContainer: {
        display: "flex",
      },
      scoopeGuidanceSpaceBetweenContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      scoopeGuidanceSpaceBetweenContainerTransforming: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 1260px)": {
          flexDirection: "column",
          paddingBottom: "20px",
        },
      },
      scopeGuidanceButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
      },
      scopeGuidanceHelpText: {
        fontSize: "16px",
        fontWeight: 300,
        fontFamily: "GT Flexa",
        lineHeight: "normal",
      },
      scopeGuidanceSubTitle: {
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "20px",
        marginTop: "20px",
        fontFamily: "GT Flexa",
      },
      scopeGuidancePDFHelperText: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "GT Flexa",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "10px",
      },
      scopeGuidanceStepTitle: {
        fontSize: "28px",
        fontWeight: 500,
        marginBottom: "20px",
        marginTop: "20px",
        fontFamily: "GT Flexa",
      },
      promtAssistantBtn: {
        width: "100%",
        height: "100px",
        background: "#FD750D",
        borderRadius: "100px",
        marginTop: "40px",
        marginBottom: "20px",
        color: "#FFFFFF",
        fontSize: "28px",
      },
      inputByPromptBtn: {
        width: "100%",
        height: "100px",
        borderRadius: "100px",
        border: "2px solid #FD750D",
        margin: "20px 0",
        color: "#FD750D",
        fontSize: "28px",
      },
      scopeSelectBtnIcon: {
        marginRight: "12px",
        width: "34px",
        height: "34px",
      },
      scopeSelectTooltip: {
        cursor: "pointer",
        height: "33px",
        width: "33px",
        marginTop: "5px",
      },
      scopeGuidancePDFTooltip: {
        cursor: "pointer",
        height: "25px",
        width: "25px",
      },
      scopeBackBtn: {
        background: "transparent",
        color: "#666666",
        border: "1px solid #666666",
        borderRadius: "30px !important",
        padding: "0px 12px",
        height: "34px",
        width: "230px",
        marginTop: "5px",
        marginRight: "10px",
        fontSize: "16px",
        lineHeight: "20px",
        "&:hover": {
          background: "#f3f3f3",
        },
        textTransform: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      scopeBackToMainBtn: {
        background: "transparent",
        color: "#666666",
        border: "1px solid #666666",
        borderRadius: "30px !important",
        padding: "0px 12px",
        height: "34px",
        width: "230px",
        marginTop: "5px",
        marginRight: "10px",
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: "GT Flexa",
        "&:hover": {
          background: "#f3f3f3",
        },
        textTransform: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        minWidth: "200px",
      },
      mainWrapper: {
        margin: "100px 40px",
        padding: "30px 0",
        background: "#E7EAEF",
        borderTopRightRadius: "12px",
        borderTopLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
        width: "100%",
      },
      titleWrapper: {
        // padding: "0px 30px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        background: "#E7EAEF",
        borderBottom: "1px solid #C2C9D7",
        position: "relative",
        margin: "0 30px",
        fontFamily: "GT Flexa",
      },
      image: {
        position: "relative",
      },
      newPumaLogo: {
        position: "absolute",
        bottom: 0,
        width: "188px",
      },
      scopePreviewButtons: {
        position: "absolute",
        right: 50,
        top: 5,
      },
      getSummaryBtn: {
        marginLeft: spacing(2),
        marginBottom: spacing(2),
        fontFamily: "GT Flexa",
        fontSize: "16px",
      },
      previousScopesWrapper: {
        // padding: "0 30px"
      },
      previousScopesTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 20,
        padding: "0 30px",
      },
      previousScopesTitle: {
        fontSize: 20,
        fontWeight: 600,
        color: "#333333",
      },
      previousScopesLegend: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#6666666",
        columnGap: 20,
      },
      previousScopesScopeLegend: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: 10,
      },
      previousScopesLookalikeLegend: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: 10,
      },
      titleTextWrapper: {
        width: "100%",
        paddingBottom: "18px",
        color: "#0B295F",
        maxWidth: "100%",
        textAlign: "center",
      },
      title: {
        marginBottom: "10px",
        width: "100%",
        fontWeight: "300",
      },
      searchWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        background: "#E7EAEF",
        // borderBottom: "1px solid #C2C9D7",
      },
      lookalikeWrapper: {
        padding: "30px 0 0 0",
        "& ol": {
          margin: 0,
          maxHeight: "calc(100vh - 480px)",
          overflowY: "overlay",
          scrollbarGutter: "stable both-edges",
          padding: 20,
        },
      },

      lookAlikeWrapperOpened: {
        position: "relative",
        paddingBottom: 48,
      },
      scope: {
        width: "100%",
        padding: "30px 0 20px 0",
        borderBottom: "1px solid #C2C9D7",
      },
      divider: {
        padding: "30px 0px",
        position: "absolute",
        height: "85%",
        background: "#C2C9D7",
        width: "1px",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      scopeTitleWrapper: {
        display: "flex",
        justifySelf: "flex-start",

        justifyContent: "space-between",
        // justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      },
      lookalikeTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",

        width: "100%",
      },
      tooltip: {
        cursor: "pointer",
      },
      tooltipWrapper: {
        display: "flex",
        justifyContent: "end",
        width: "200px",
      },
      activePlus: {
        cursor: "pointer",
        color: "#FD750D",
        marginRight: "24px",
        transition: "all 0.2s",
        width: 20,
        height: 20,
      },
      plus: {
        cursor: "pointer",
        color: "#999999",
        marginRight: "24px",
        width: 20,
        height: 20,
      },
      scopeTitle: {
        display: "flex",
        justifyContent: "center",
        columnGap: 10,
        alignItems: "center",
        color: "#0B295F",
        fontSize: "20px",
        fontWeight: 600,
        textAlign: "center",
        marginRight: "30px",
      },
      scopeTextarea: {
        borderColor: "#C2C9D7",
        outline: "none",
        resize: "none",
        borderRadius: "8px",
        padding: "12px",
        color: palette.secondary.dark,
        width: "100%",
        "&:focus": {
          borderColor: "#333333",
        },
        "&::placeholder": {
          fontSize: 14,
          lineHeight: "18px",
          fontFamily: "GT Flexa, sans-serif",
          color: palette.text.lighter,
        },
        minHeight: "60px",
      },
      exampleButton: {
        background: "transparent",
        color: "#666666",
        border: "1px solid #666666",
        borderRadius: "30px !important",
        padding: "0px 12px",
        height: "36px",
        fontSize: "16px",
        lineHeight: "20px",
        "&:hover": {
          background: "#f3f3f3",
        },
        textTransform: "none",
        whiteSpace: "nowrap",
      },
      lookalikeTitle: {
        color: "#0B295F",
        fontSize: "20px",
        fontWeight: 600,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      },
      lookalikeInput: {
        width: "100%",
        height: "42px",
        border: "1px solid transparent",
        borderRadius: "8px",
        padding: "0 12px",
        cursor: "pointer",
        marginBottom: "20px",
      },
      lookalikeDescription: {
        maxWidth: "340px",
        color: "#485E87",
      },
      scopeDescriptionWrapper: {
        display: "flex",
        justifyContent: "space-between",
      },
      scopeDescription: {
        maxWidth: "340px",
        color: "#485E87",
      },
      filterWrapper: {
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #C2C9D7",
      },
      filterTitleWrapper: {
        padding: "30px 0px 20px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "12px",
      },
      filterTitlesBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
      },
      fitlerTitle: {
        marginRight: "20px",
        fontSize: "20px",
        color: "#0B295F",
        fontWeight: 600,
      },
      fitlerDescription: {
        maxWidth: "650px",
        color: "#485E87",
      },
      contentWrapper: {
        padding: "0px 30px",
        background: "#E7EAEF",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
      },
      multiselect: {
        maxWidth: "320px",
        width: "320px",
      },
      inputsCountryWrapper: {
        display: "flex",
        justifyContent: "center",
        columnGap: 60,
      },
      "& .react-select-container": {
        border: "2px solid violet",
      },
      inputsWrapper: {
        display: "flex",
        flexDirection: "column",
        rowGap: 16,
      },
      inputsIndustryWrapper: {
        display: "flex",
        justifyContent: "center",
        columnGap: 60,
      },
      buttonWrapper: {
        paddingTop: "30px",
        width: "100%",
        textAlign: "center",
      },
      button: {
        height: 80,
        width: 320,
        background: "#FD750D",
        color: "#FFFFFF",
        fontSize: "28px",
        borderRadius: "600px",
        textTransform: "none",
        "&:disabled": {
          background: "#EFEFEF",
        },
        "&:hover": {
          background: "#0B295F",
        },
      },
      buttonIcon: {
        marginRight: "12px",
      },
      promptTitle: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
      prompt: {
        cursor: "pointer",
        border: "1px solid #999999",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        marginRight: 10,
      },
      activePrompt: {
        cursor: "pointer",
        border: "1px solid #FD750D",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        marginRight: 10,
      },

      subActivePrompt: {
        cursor: "pointer",
        margin: 3,
        border: "1px solid #FD750D",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        background: "#FD750D",
      },
      inputContentWrapper: {
        width: "100%",
        background: "#FFFFFF",
        // border: "1px solid #333333",
        borderRadius: 8,
      },
      searchBox: {
        position: "relative",
        "& input": {
          width: "100%",
          ...typography.body,
          color: palette.common.black,
          outline: "none",
          padding: spacing(2),
          background: palette.common.white,
          border: `1px solid #C2C9D7`,
          borderRadius: spacing(1),
          fontFamily: "GT Flexa",
          "&::placeholder": {
            ...typography.bodySmall,
            color: palette.text.lighter,
            fontFamily: "GT Flexa",
          },
          "&:active, &:focus": {
            border: `1px solid #000000`,
          },
        },
        "& .ais-SearchBox-submit, & .ais-SearchBox-reset, & input[type='search' i]::-webkit-search-cancel-button":
          {
            display: "none",
          },
      },
      searchBoxDisable: {
        "& input": {
          background: ({ isDisabled }) => (isDisabled ? "#E7EAEF" : "#ffffff"),
          pointerEvents: ({ isDisabled }) => (isDisabled ? "none" : "auto"),
        },
      },
      hit: {
        padding: `${spacing(1)}px ${spacing(0)}px`,
        borderLeft: `2px solid transparent`,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "&:hover": {
          background: palette.primary.backgroundLight,
          cursor: "pointer",
          "& $hitDescription": {
            color: palette.primary.dark,
          },
        },
        [breakpoint.down("sm")]: {
          padding: `${spacing(2)}px`,
        },
      },
      hitSelected: {
        borderLeft: `2px solid ${palette.primary.dark}`,
        [breakpoint.down("sm")]: {
          background: palette.primary.backgroundLight,
        },
      },
      hitTitle: {
        fontWeight: 500,
        minWidth: "40%",
      },
      lookAlikeRoot: { padding: 0 },
      addMoreDetailsCta: {
        display: "flex",
        margin: "10px 10px",
        padding: "5px 0",
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid #999999",
        borderBottom: "1px solid #999999",
      },
      input: {
        // "&.Mui-focused": {
        //     "&:after": {
        //         borderBottom: `1px solid ${palette.text.lighter} !important`,
        //     },
        //     "&:before": {
        //         borderBottom: `1px solid ${palette.text.lighter} !important`,
        //     },
        // },
        // "&:after": {
        //     borderBottom: `1px solid ${palette.text.lighter} !important`,
        // },
        // "&:before": {
        //     borderBottom: `1px solid ${palette.text.lighter} !important`,
        // },
      },
      goBack: {
        marginTop: spacing(3),
        marginRight: "auto",
        [breakpoint.down("sm")]: {
          marginTop: 0,
        },
      },
      backToCompany: {
        fontFamily: "GT Flexa Mono, sans-serif",
        cursor: "pointer",
        [breakpoint.down("sm")]: {
          textAlign: "center",
          display: "block",
        },
      },
      backToArray: {
        marginTop: spacing(3),
        stroke: palette.secondary.dark,
        transform: "rotate(180deg)",
        marginRight: spacing(1),
      },
      findSimilarCompanies: {
        marginTop: spacing(2),
        [breakpoint.down("sm")]: {
          display: "inherit",
          margin: "16px auto 8px auto",
        },
      },

      labelShrinked: {
        ...typography.bodyXs,
        fontFamily: "GT Flexa",
        transform: "none",
        marginTop: -4,
        color: palette.text.light,
      },
      focusedLabel: {
        color: `${palette.text.lighter} !important`,
      },
      form: {
        maxWidth: 480,
        alignSelf: "center",
      },
      waveLoader: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      lookAlikeLoader: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      lookAlikesTitle: {
        display: "flex",
        alignItems: "center",
      },
      betaWrapper: {
        height: 25,
        display: "flex",
      },

      // new search
      mainSearchBoxWrapper: {
        width: "70vw",
        maxWidth: "1500px",
        margin: "0 auto",
        paddingTop: "7vh",
        [breakpoint.down("lg")]: {
          width: "100%",
          marginBottom: "50px",
          padding: spacing(2),
        },
      },
      mainSearchBox: {
        position: "relative",
        height: "100%",
        width: "100%",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        border: `1px solid ${palette.success.backgroundDark}`,
        background: palette.success.backgroundLight,
        // [breakpoint.down("md")]: {
        //     borderTopRightRadius: 0,
        //     borderBottomRightRadius: 0,
        //     borderBottomLeftRadius: 0,
        //     borderRight: 0,
        //     borderLeft: 0,
        //     borderBottom: 0,
        //     background: "transparent"
        // },
      },
      mainSearchBoxTabTwoVariant: {
        borderTopLeftRadius: "20px",
        borderTopRightRadius: 0,
      },
      searchTypeTab: {
        height: "50px",
        width: "50%",
        borderLeft: `1px solid ${palette.success.backgroundDark}`,
        borderTop: `1px solid ${palette.success.backgroundDark}`,
        borderRight: `1px solid ${palette.success.backgroundDark}`,
        borderBottom: `1px solid ${palette.success.backgroundLight}`,
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px",
        background: palette.success.backgroundLight,
        color: palette.success.dark,
        position: "relative",
        zIndex: 1,
        marginBottom: "-1px",
        fontSize: spacing(3),
        "& :hover": {
          cursor: "pointer",
        },
        [breakpoint.down("md")]: {
          width: "100%",
          borderLeft: 0,
          borderRight: 0,
          borderBottom: 0,
        },
      },
      tableContainer: {
        bottom: 0,
        padding: "0px 0px",
        boxShadow: "none",
        background: "#FD750D",
        "& tbody tr:hover": {
          background: "#FD750D",
          "& .date": {
            color: "#FFFFFF",
          },
          "& svg path": {
            fill: "#FFFFFFF",
            color: "#FFFFFF",
          },
          "& .delete": {
            color: "#FFFFFF",
          },
          "& .scope": {
            color: "#FFFFFF",
          },
        },
      },
      table: {
        minWidth: "100%",
        "& tbody tr": {
          borderBottom: "1px solid #C2C9D7",
          alignItems: "center",
          width: "100%",
        },
        "& tbody tr td": {
          padding: "20px 30px",
        },
        "& th": {
          padding: "20px 30px",
        },
      },
      date: {
        fontSize: 14,
        color: "#666666",
        cursor: "pointer",
      },
      tableScope: {
        fontSize: 14,
        color: "#333333",
        lineHeight: 2,
      },

      header: {
        fontSize: 14,
        fontWeight: 600,
        color: "#333333",
        background: "#E7EAEF",
      },
      delete: {
        cursor: "pointer",
        color: "#999999",
      },
      lookalike: {
        color: "#007A5E",
      },
      searchScope: {
        color: "#0B295F",
      },
      tableTooltip: {
        lineHeight: 2,
      },
      tbody: {
        overflowY: "scroll",
      },
      head: {
        position: "sticky",
        top: "-40px",
        backgroundColor: "#ffffff" /* Set your header background color */,
        zIndex: 100,
      },
      dialogContent: {
        position: "relative",
      },

      x: {
        cursor: "pointer",
        position: "absolute",
        top: "0",
        right: 10,
        color: "#FD750D",
        fontSize: 24,
        margin: 0,
        height: 0,
      },
      tableTitle: {
        fontSize: "20px",
        fontWeight: 500,
      },
      paragraph: {
        fontSize: "16px",
        fontWeight: 300,
        margin: 0,
        marginBottom: 120,
      },
      buttons: {
        width: "100%",
        textAlign: "right",
      },
      cancelButton: {
        marginRight: 10,
      },
      tooltipTitle: {
        fontFamily: "GT Flexa",
        lineHeight: 1.4,
        fontSize: 14,
        fontWeight: 400,
      },
    }),
);
