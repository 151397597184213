import { call } from "redux-saga/effects";
import axios from "axios";
import {
  CHECK_SEARCH_COUNT_URL,
  GET_SEARCH_RESULTS_URL,
  getMlSummaryUrl,
  RESET_SEARCH_COUNT_URL,
  SEARCHES_URL,
} from "../../../constants/endpoints";

function* postSearch(
  description,
  searchSectors,
  location,
  scope,
  searchType,
  lookAlikeName,
  perPage,
  resultsLimit,
  scope_guide_step_1,
  scope_guide_step_2,
  scope_guide_step_3,
  scope_guide_step_4,
  scope_guide_step_5,
  multi_tenant_entities,
  PDF_ID,
) {
  const data = {
    description: description && description.length > 0 ? description : null,
    sectors:
      searchType === 1 && searchSectors && searchSectors.length > 0
        ? JSON.stringify(searchSectors)
        : null,
    location: searchType === 1 && location ? JSON.stringify(location) : null,
    scope,
    "search-type": searchType,
    name: lookAlikeName,
    "per-page": perPage,
    "results-limit": resultsLimit,
    scope_guide_step_1,
    scope_guide_step_2,
    scope_guide_step_3,
    scope_guide_step_4,
    scope_guide_step_5,
    multi_tenant_entities,
    PDF_ID,
  };
  {
    const data = {
      description: description && description.length > 0 ? description : null,
      sectors:
        searchType === 1 && searchSectors && searchSectors.length > 0
          ? JSON.stringify(searchSectors)
          : null,
      location: searchType === 1 && location ? JSON.stringify(location) : null,
      scope,
      "search-type": searchType,
      name: lookAlikeName,
      "per-page": perPage,
      "results-limit": resultsLimit,
      scope_guide_step_1,
      scope_guide_step_2,
      scope_guide_step_3,
      scope_guide_step_4,
      scope_guide_step_5,
      multi_tenant_entities,
      PDF_ID,
    };
  }

  searchType === 2 && delete data.location;

  const response = yield call(axios, {
    method: "POST",
    url: GET_SEARCH_RESULTS_URL,
    withCredentials: true,
    data,
  });

  return response.data;
}

function* getMlSummary(data) {
  const response = yield call(axios, {
    method: "GET",
    url: getMlSummaryUrl(data.sid),
    data,
    withCredentials: true,
  });
  return response.data;
}

function* getCheckSearchCount() {
  const response = yield call(axios, {
    method: "GET",
    url: `${CHECK_SEARCH_COUNT_URL}`,
    withCredentials: true,
  });
  return response.data;
}

function* postResetSearchCount() {
  const response = yield call(axios, {
    method: "POST",
    url: `${RESET_SEARCH_COUNT_URL}`,
    withCredentials: true,
  });
  return response;
}

function* postSetSearchCount() {
  const response = yield call(axios, {
    method: "POST",
    url: `${CHECK_SEARCH_COUNT_URL}`,
    withCredentials: true,
  });
  return response;
}

function* deleteSavedSearches(payload) {
  const { sid, savedSearch } = payload;
  const data = { sid };
  const response = yield call(axios, {
    method: "DELETE",
    url: `${SEARCHES_URL}${savedSearch ? "?ss=true" : ""}`,
    data,
    withCredentials: true,
  });
  return response;
}

export {
  postSearch,
  getCheckSearchCount,
  postResetSearchCount,
  postSetSearchCount,
  deleteSavedSearches,
  getMlSummary,
};
